(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("jQuery"));
	else if(typeof define === 'function' && define.amd)
		define(["jQuery"], factory);
	else if(typeof exports === 'object')
		exports["scripts"] = factory(require("jQuery"));
	else
		root["sfp"] = root["sfp"] || {}, root["sfp"]["scripts"] = factory(root["jQuery"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_xeH2__) {
return 