import { HEADER_HEIGHT } from './constants';

export const scrollToElement = function ({
  element,
  duration = 0,
  scrollTopDifference = 0,
  ignoreHeaderHeight = false,
  callBack,
}) {
  let scrollTop = $(element).offset().top + scrollTopDifference;

  if (!ignoreHeaderHeight) {
    scrollTop -= HEADER_HEIGHT;
  }

  if (duration) {
    let count = 0;
    // jQuery calls the "complete"-callback foreach target element (html and body)
    let animationsCompleteCallBack = callBack
      ? function () {
          count++;
          if (count === 2) {
            callBack();
          }
        }
      : undefined;
    jQuery('html, body').animate(
      {
        scrollTop,
      },
      duration,
      animationsCompleteCallBack
    );
  } else {
    jQuery('html, body').scrollTop(scrollTop);
    if (callBack) callBack();
  }
};

export const setLocationHash = function (content) {
  const hash = content[0] === '#' ? content : '#' + content;

  if (history.pushState) {
    history.pushState(null, null, hash);
  } else {
    location.hash = hash;
  }
};

export class ScrollTopCache {
  constructor(element = window) {
    this.$element = $(element);
    this.update();
  }

  /**
   * Updates the cache with the current scrollTop position
   */
  update() {
    this.scrollTop = this.$element.scrollTop();
  }

  /**
   * Applies the current cached scrollTop position
   */
  apply() {
    this.$element.scrollTop(this.scrollTop);
  }
}
