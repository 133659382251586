import {
  HEADER_CLASS,
  HEADER_WRAPPER_ACTIVE_CLASS,
  HEADER_WRAPPER_CLASS,
  CONTENT_ELEMENT_CLASS,
  MAIN_CONTENT_SELECTOR,
  SCROLL_TOP_DIFFERENCE,
  HEADER_PARENT_CLASS,
} from './constants';
import { getElementIdFromUid, getUidFromElementId } from './util';
import { scrollToElement } from '../util';

export const getAccordionHeaders = function () {
  return $(`.${HEADER_CLASS}`);
};

const getAccordionHeaderWrappers = function () {
  return $(`.${HEADER_WRAPPER_CLASS}`);
};

export const getAccordionHeaderByAccordionItemUid = function (
  accordionItemUid
) {
  return $(`.${HEADER_CLASS}[data-content-uid="${accordionItemUid}"]`);
};

/**
 * Iterates through all content elements and searches for the parent accordion item uid of any content uid
 *
 * @param childContentUid
 *
 * @returns {boolean|string}
 */
export const findAccordionItemUidByChildContentUid = function (
  childContentUid
) {
  let currentAccordionItemUid = false;
  let result = false;
  $.each(
    $(MAIN_CONTENT_SELECTOR).find(`.${CONTENT_ELEMENT_CLASS}`),
    function (idx, contentEl) {
      const $el = $(contentEl);
      const elId = $el.attr('id');
      if (!elId) {
        return;
      }

      const contentUid = getUidFromElementId(elId);
      const $header = $el.find(`.${HEADER_CLASS}`);
      if ($header.length) {
        currentAccordionItemUid = contentUid;
      } else if (contentUid === childContentUid && currentAccordionItemUid) {
        result = currentAccordionItemUid;
        return false;
      }
    }
  );

  return result;
};

/**
 * The accordion headers are children of some contentElements, find them and
 * add classes for css and future direct js targeting
 */
export const initializeContentElementClasses = function () {
  $.each(getAccordionHeaders(), function (idx, el) {
    $(el)
      .parents(`.${CONTENT_ELEMENT_CLASS} > *`)
      .addClass(HEADER_PARENT_CLASS);
    $(el).parents(`.${CONTENT_ELEMENT_CLASS}`).addClass(HEADER_WRAPPER_CLASS);
  });
};

const updateSetActiveAccordionWrapper = function (state) {
  if (!state.activeAccordionItemUid) {
    return;
  }

  getAccordionHeaderByAccordionItemUid(state.activeAccordionItemUid)
    .parents(`.${HEADER_WRAPPER_CLASS}`)
    .addClass(HEADER_WRAPPER_ACTIVE_CLASS);
};

/**
 * Updates the accordion view and triggers a window resize afterwards so that accordion body content will be responsive
 *
 * @param state
 */
export const update = function (state) {
  getAccordionHeaderWrappers().removeClass(HEADER_WRAPPER_ACTIVE_CLASS);
  updateSetActiveAccordionWrapper(state);
  $(window).trigger('resize');
};

export const updateFocusedElement = function (state, animate) {
  if (!state.focusedContentUid) {
    return;
  }

  const $targetEl = $(`#${getElementIdFromUid(state.focusedContentUid)}`);
  if (!$targetEl.length) {
    return;
  }

  const scrollConfig = {
    element: $targetEl,
    duration: animate ? 500 : 0,
    scrollTopDifference: SCROLL_TOP_DIFFERENCE,
    ignoreHeaderHeight: true,
  };

  scrollToElement(scrollConfig);
};
