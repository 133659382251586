// https://awik.io/check-if-element-is-inside-viewport-with-javascript/
/**
 * Checks if the element is currently visible in the viewport
 *
 * @param {HTMLElement} el
 */
const isElementInViewport = function (el) {
  const bounding = el.getBoundingClientRect();
  const myElementHeight = el.offsetHeight;

  return (
    bounding.top >= -myElementHeight &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) +
        myElementHeight
  );
};

const MAX_LOAD_TIMEOUT = 2500;

/**
 * Collects all lazy functions which will be called at some later moment
 */
const plannedFuncs = [];

/**
 * Flags if the funcs already are loaded
 */
let loaded = false;

/**
 * Calls all lazy functions (once)
 */
const load = function () {
  loaded = true;
  for (const func of plannedFuncs) {
    func();
  }
};

const $window = $(window);
const windowScrolltop = $window.scrollTop();
const useTimeout =
  typeof requestAnimationFrame !== 'null' ? requestAnimationFrame : setTimeout;

/**
 * Calls the provided function as late as possible
 *
 * 1. If selector is provided and element in viewport -> Call the func directly
 * 2. If selector applied but element is not in the DOM -> The function will be DISCARDED
 * 3. If no selector is provided and initial scrollPos > 0 -> Call the func directly
 * 4. Call the func if the user starts scrolling
 * 5. If non of the above happened: Call the function after MAX_LOAD_TIMEOUT (2.5 seconds)
 *
 * @param {Function} func
 * @param {string|undefined} selector
 */
export const lazyInit = function (func, selector) {
  const lazyFunc = function () {
    useTimeout(func);
  };

  if (loaded) {
    return lazyFunc();
  }

  if (selector) {
    const $els = $(selector);
    if ($els.length === 0) {
      return;
    }

    $els.each(function (k, el) {
      if (isElementInViewport(el)) {
        lazyFunc();
        return false;
      }
    });
  }

  if (windowScrolltop > 0 && !selector) {
    return lazyFunc();
  }

  plannedFuncs.push(lazyFunc);
};

/**
 * Registers the lazyInit sideEffects
 *
 * See lazyInit for a description of the business logic
 */
const init = function () {
  setTimeout(function () {
    if (loaded) {
      return;
    }
    load();
  }, MAX_LOAD_TIMEOUT);

  $window.on('scroll.sfpLazyInit', function () {
    if (loaded) {
      return;
    }
    $window.off('scroll.sfpLazyInit');
    load();
  });
};

init();
