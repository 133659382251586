import throttle from 'lodash.throttle';

// from https://osvaldas.info/auto-hide-sticky-header with modifications
const useSticky = function ({ element, hiddenClass, navOffset = 143 }) {
  var throttleTimeout = 500,
    $element = $(element);

  if (!$element.length) return true;

  var $window = $(window),
    wHeight = 0,
    wScrollCurrent = 0,
    wScrollBefore = 0,
    wScrollDiff = 0,
    $document = $(document),
    dHeight = 0;

  const stickyScrollHandler = function () {
    dHeight = $document.height();
    wHeight = $window.height();
    wScrollCurrent = $window.scrollTop();
    wScrollDiff = wScrollBefore - wScrollCurrent;

    if (wScrollCurrent <= 0)
      // scrolled to the very top; element sticks to the top
      $element.removeClass(hiddenClass);
    else if (wScrollDiff > 0 && $element.hasClass(hiddenClass))
      // scrolled up; element slides in
      $element.removeClass(hiddenClass);
    else if (wScrollDiff < 0) {
      // scrolled down
      if (wScrollCurrent + wHeight >= dHeight && $element.hasClass(hiddenClass))
        // scrolled to the very bottom; element slides in
        $element.removeClass(hiddenClass);
      else if (wScrollCurrent >= navOffset)
        // scrolled down; element slides out
        $element.addClass(hiddenClass);
    }

    wScrollBefore = wScrollCurrent;
  };

  stickyScrollHandler();

  $window.on('scroll', throttle(stickyScrollHandler, throttleTimeout));
};

export const initialize = function () {
  useSticky({
    element: $('header.Header'),
    hiddenClass: 'header-hidden',
  });
  useSticky({
    element: $('.Header__OffCanvasToggle'),
    hiddenClass: 'header-hidden',
  });
};
