import { HEADER_CLASS, MAIN_CONTENT_SELECTOR } from './constants';
import { MOBILE_SCREEN_WIDTH } from '../constants';
import {
  initializeContentElementClasses,
  getAccordionHeaders,
  update as updateView,
  updateFocusedElement,
} from './view';
import { setLocationHash } from '../util';
import {
  createState,
  setActiveAccordionItemUid,
  setContentUid,
  setFocusedContentUid,
} from './store';
import { getContentUidFromLocationHash } from './util';

// Naming schema:
// accordionItem: This is one accordion "part" including its heading and corresponding content
// accordionHeader: Accordion header element of an accordionItem
// accordionHeaderWrapper: "Content element" around the header
// accordionHeaderParent: First parent around accordionHeader inside accordionHeaderWrapper
// accordionChild: A single child content of an accordionItem

// Structure Logic: ".accordionHeaderWrapper > .accordionHeaderParent .accordionHeader"
// The important concept is, that "accordion item header" and "accordion item content" might be one contentElement or
// multiple independent ones:

// accordion item with single contentElement structure:
// {
// div: contentElement (accordionHeaderWrapper)
//     div: headerParent (accordionHeaderParent) (optional)
//         header (accordionHeader)
//     div: content (accordionChild)
// }

// accordion item with multiple contentElements structure:
// {
// div: contentElement (accordionHeaderWrapper)
//     div: headerParent (accordionHeaderParent) (optional)
//         header (accordionHeader)
// div: contentElement
//     div: content (accordionChild)
// div: contentElement
//     div: content (accordionChild)
// div: contentElement
//     div: content (accordionChild)
// div: contentElement
//     div: content (accordionChild)
// }

// accordion item mixed case (content element with title and content, and more content elements afterwards)
// {
// div: contentElement (accordionHeaderWrapper)
//     div: headerParent (accordionHeaderParent) (optional)
//         header (accordionHeader)
//     div: content (accordionChild)
// div: contentElement
//     div: content (accordionChild)
// div: contentElement
//     div: content (accordionChild)
// }

// Essentially all contentElements after a header belong to that header and a single accordionItem!
// The accordionHeaderWrapper is the key to know when a new accordionItem begins

const attachAccordionHeaderClickHandler = function (state) {
  $(MAIN_CONTENT_SELECTOR).on('click', `.${HEADER_CLASS}`, function (event) {
    event.preventDefault();

    let contentUid = $(event.currentTarget).data('content-uid');

    // Accordion toggle
    if (contentUid === state.activeAccordionItemUid) {
      contentUid = false;
    }

    setActiveAccordionItemUid(state, contentUid);
    setFocusedContentUid(state, contentUid);

    if (state.activeAccordionItemUid) {
      setLocationHash(`c${state.activeAccordionItemUid}`);
    }

    updateView(state);
    setTimeout(function () {
      updateFocusedElement(state, true);
    }, 0);
  });
};

const attachHashChangeHandler = function (state) {
  window.addEventListener('hashchange', function () {
    const contentUid = getContentUidFromLocationHash();
    if (!contentUid || state.focusedContentUid === contentUid) {
      return;
    }

    setContentUid(state, contentUid);
    updateView(state);
    setTimeout(function () {
      updateFocusedElement(state, true);
    }, 0);
  });
};

/**
 * Main entry point of the component
 *
 * Initializes the component, renders accordion state and attaches event handlers
 * Will only do its stuff if atleast one accordionHeader can be found
 */
export const initialize = function (state = createState()) {
  const $accordionHeaders = getAccordionHeaders();
  if (!$accordionHeaders) {
    return;
  }

  initializeContentElementClasses();

  // Open first navigation dropdown without scroll into view on mobile if there is only one
  if ($accordionHeaders.length == 1) {
    setActiveAccordionItemUid(state, $accordionHeaders.data('content-uid'));
  }

  updateView(state);
  if (document.documentElement.clientWidth <= MOBILE_SCREEN_WIDTH) {
    setTimeout(function () {
      updateFocusedElement(state, false);
    }, 0);

    attachHashChangeHandler(state);
  }

  attachAccordionHeaderClickHandler(state);
};
