import 'regenerator-runtime/runtime';
import './Header';
import './Rootline';
import EventoLinks from './EventoLinks';
import { initialize as initializeMobileContentAccordion } from './MobileContentAccordion';
import { initialize as initializeOffCanvas } from './OffCanvas';
import { initialize as initializeStickyElement } from './StickyElement';
import { lazyInit } from './LazyInit';

// 1 Prio - Always needed
require('./Modernizr.js');
window.Modernizr.addTest(
  'boxDecorationBreak',
  window.Modernizr.testAllProps('boxDecorationBreak', 'slice')
);
initializeStickyElement();
initializeMobileContentAccordion();
new EventoLinks();

// 2 Prio, only needed for active user interactions
setTimeout(function () {
  import('./MainNav');
  import('./Search');
  initializeOffCanvas();
});

// 3 Prio, important, - but only on specific subsites
setTimeout(function () {
  import('./Form');
  import('../Plugins/Form/JavaScript/index.js');
  import('./Video');
});

lazyInit(async function () {
  import('./Tooltips');
}, '[data-bs-toggle="tooltip"]');

// 4 Prio, who needs this stuff?
lazyInit(async function () {
  import('./Sliders');
}, '.Slider');
lazyInit(async function () {
  import('./Hyphens');

  import('./Apartment');
  import('./Job');

  import('./Randomize');

  const AnchorNavigation = await import('./AnchorNavigation');
  new AnchorNavigation.default();

  const Accordion = await import('./Accordion');
  new Accordion.default();

  const Lightbox = await import('./Lightbox');
  const lightbox = new Lightbox.default();
  lightbox.init();

  import('./ToTop');

  $('.frame-type-template_accordion').each(function () {
    if (
      $(this)
        .parent()
        .next()
        .children()
        .eq(0)
        .is('.frame-type-template_accordion')
    ) {
      $(this).addClass('notLast');
    }
  });

  $('.HeaderImage__ScrollDownLink').on('click', function (event) {
    event.preventDefault();
    $('html, body').animate({ scrollTop: window.innerHeight - 12 }, 1000);
  });

  $('.Sticker__Button--ScrolldownLink').on('click', function (event) {
    event.preventDefault();
    $('html, body').animate({ scrollTop: window.innerHeight - 12 }, 1000);
  });

  $('.StudyPathSticky__MoreLink').on('click', function (event) {
    event.preventDefault();

    var $root = $('html, body');

    $root.animate(
      {
        scrollTop: $('#anchor8').offset().top,
      },
      1000
    );
  });

  import('./SmartContent');
  import('./KeyFactBox');
});
