import { OVERLAY_ACTIVE_CLASS } from '../constants';
import { ScrollTopCache } from '../util';

const getNestedActiveBackButton = function () {
  const $activeBackButtonItems = $(
    `.Navigation__List--OffCanvas.active > li:first-child `
  );
  if (!$activeBackButtonItems.length) {
    return;
  }

  return $activeBackButtonItems.last();
};

const getNestedActiveUl = function ($nestedActiveBackButton) {
  if (!$nestedActiveBackButton)
    $nestedActiveBackButton = getNestedActiveBackButton();
  return $nestedActiveBackButton.closest('ul');
};

const updateBottomBar = function () {
  const $bottomBar = $('.OffCanvasNavigation__BottomBar');

  const $newBottomBarBackButton = $('<div />', {
    class: 'OffCanvasNavigation__BackButton',
  });
  const $newBottomBarBackButtonLink = getNestedActiveBackButton()
    .children('a')
    .clone();
  $newBottomBarBackButton.append($newBottomBarBackButtonLink);

  $bottomBar.html('');
  $bottomBar.append($newBottomBarBackButton);
};

export const initialize = function () {
  let $offcanvasNavigation = $('.OffCanvasNavigation');
  if (!$offcanvasNavigation.length) {
    return;
  }

  let $activeNavigationUl = getNestedActiveUl();
  let scrollTopCache = new ScrollTopCache();

  updateBottomBar();

  $('.Header__OffCanvasToggle').on('click', function (e) {
    e.preventDefault();
    scrollTopCache.update();
    $('html, .OffCanvasNavigation').toggleClass(OVERLAY_ACTIVE_CLASS);
  });

  $('.OffCanvasNavigation__Close a').on('click', function (e) {
    e.preventDefault();
    $('html, .OffCanvasNavigation').toggleClass(OVERLAY_ACTIVE_CLASS);
    $('.MainNavigation__Item--Quick').removeClass('active');
    scrollTopCache.apply();
  });

  $offcanvasNavigation.on('click', 'a.back:not(.root)', function (e) {
    e.preventDefault();
    $activeNavigationUl.removeClass('active');
    $activeNavigationUl = getNestedActiveUl();
    $('.MainNavigation__Item--Quick').removeClass('active');
    updateBottomBar();
  });
  $('.OffCanvasNavigation a.down').on('click', function (e) {
    e.preventDefault();
    $activeNavigationUl = $(this).siblings('ul');
    $activeNavigationUl.addClass('active');
    $('.MainNavigation__Item--Quick').removeClass('active');
    updateBottomBar();
  });
};

$('.Navigation__List--OffCanvas').on('scroll', function (e) {
  $('.MainNavigation__Item--Language').removeClass('active');
});
