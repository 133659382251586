import { CONTENT_ID_HASH_REGEX } from './constants';

export const getUidFromElementId = function (elementId) {
  // Element id = c1234, element uid = 1234
  return parseInt(elementId.slice(1));
};

export const getElementIdFromUid = function (contentElementUid) {
  return `c${contentElementUid}`;
};

export const getContentUidFromLocationHash = function () {
  const hash = location.hash;
  if (!hash) return;

  const matches = hash.match(CONTENT_ID_HASH_REGEX);
  if (!matches || matches[1] == null) {
    return;
  }

  return matches[1];
};
