import { getContentUidFromLocationHash } from './util';
import {
  findAccordionItemUidByChildContentUid,
  getAccordionHeaderByAccordionItemUid,
} from './view';

export const createState = function () {
  let state = {
    // TYPO3 content uid of the active accordion item
    activeAccordionItemUid: false,
    // TYPO3 content uid of the focused/anchor element
    focusedContentUid: false,
  };

  const contentUid = getContentUidFromLocationHash();
  setContentUid(state, contentUid);

  return state;
};

/**
 * Updates the active accordion item and also the focused content element by contentUid
 *
 * @param {*} state
 * @param {*} contentUid
 */
export const setContentUid = function (state, contentUid) {
  if (contentUid) {
    setActiveAccordionItemUid(state, contentUid);
    setFocusedContentUid(state, contentUid);
  }

  // The anchor content uid is not an accordion item uid, so its probably the uid from an accordion child
  if (!getAccordionHeaderByAccordionItemUid(contentUid).length) {
    const accordionItemUId = findAccordionItemUidByChildContentUid(
      state.focusedContentUid
    );
    setActiveAccordionItemUid(state, accordionItemUId);
  }
};

export const setActiveAccordionItemUid = function (state, contentUid) {
  state.activeAccordionItemUid =
    typeof contentUid === 'boolean' ? contentUid : parseInt(contentUid);
};

export const setFocusedContentUid = function (state, contentUid) {
  state.focusedContentUid =
    typeof contentUid === 'boolean' ? contentUid : parseInt(contentUid);
};
