const init = function () {
  const rootLine = window.sfp.rootLine;
  if (!rootLine) {
    return;
  }

  const pageUid = window.sfp.rootLine[0];
  if (pageUid == null) {
    return;
  }

  const navIdSelector = window.sfp.rootLine
    .map(function (uid) {
      return '[data-nav-id=' + uid + ']';
    })
    .join(',');
  const $navItems = $('.Header,.OffCanvasNavigation').find(navIdSelector);

  $navItems.each(function (_, v) {
    const uid = parseInt(v.dataset.navId);

    let itemClasses = ['active'];
    if (v.tagName !== 'UL' && pageUid === uid) {
      itemClasses.push('current');
    }

    v.classList.add.apply(v.classList, itemClasses);
  });
};

init();
