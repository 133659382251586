/**
 *
 */
class EventoLinks {
  /**
   *
   */
  constructor() {
    jQuery('[data-evento="1"]').each((index, item) => {
      this.setCorrectUrl(item);
    });
    jQuery('.Sticker__Link[data-evento="1"]').each((index, item) => {
      jQuery(item).parent().appendTo(jQuery('.StickerContainer'));
    });
    jQuery('.Sticker').each((index, item) => {
      this.addSizeClass(item);
    });
  }

  /**
   *
   * @param item
   */
  addSizeClass(item) {
    const $el = jQuery(item);
    const text = $el.text().trim();

    if (text.length > 20) {
      $el.addClass('Sticker__Medium');
    } else {
      $el.addClass('Sticker__Small');
    }
  }

  /**
   *
   * @param link
   */
  setCorrectUrl(link) {
    const $link = jQuery(link);
    const url = $link.attr('data-url');
    const param = $link.attr('data-param');

    const params = this.searchToParams(window.location.search);
    console.log(params);
    if (!params[param]) {
      $link.remove();
    }

    $link.attr(
      'href',
      url
        .replace(`:${param}`, params[param])
        .replace(`:${param}`, params[param])
    );
  }

  /**
   *
   * @param {String} search
   * @returns {{}}
   */
  searchToParams(search) {
    const [, parameterString] = search.split('?');

    if (!parameterString) return {};

    const parameters = parameterString.split('&').map((string) => {
      const [key, value] = string.split('=');
      return { key, value };
    });

    return parameters.reduce((prev, cur) => {
      prev[cur.key] = cur.value;
      return prev;
    }, {});
  }
}

export default EventoLinks;
