export const HEADER_CLASS = 'MobileContentAccordionHeader';
export const HEADER_PARENT_CLASS = HEADER_CLASS + 'Parent';
export const HEADER_WRAPPER_CLASS = HEADER_CLASS + 'Wrapper';
export const HEADER_WRAPPER_ACTIVE_CLASS = HEADER_WRAPPER_CLASS + '--active';
export const CONTENT_ELEMENT_CLASS = 'ContentElement';

export const SCROLL_TOP_DIFFERENCE = -40;

export const MAIN_CONTENT_SELECTOR = '.Content--Main';

export const CONTENT_ID_HASH_REGEX = /#c(\d*)/;
